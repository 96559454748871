.slideInFromRight {
    position: relative;
    transform: translateX(-150px);
    opacity: 0;
    transition: 0.8s all ease;
}

.slideInFromLeft {
    position: relative;
    transform: translateX(150px);
    opacity: 0;
    transition: 0.8s all ease;
}

.slideInFromBottom {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 0.8s all ease;
}

.slideInFromRight.active, .slideInFromLeft.active {
    transform: translateX(0);
    opacity: 1;
}

.slideInFromBottom.active {
    transform: translateY(0);
    opacity: 1;
}