#navIcon {
    height: 3rem;
    width: auto;
}

.navLinkText {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #86868b;
    display: block;
}

.navLinkText:hover {
    color: #3164f4
}

#navbar {
    background-color: transparent;
    width: 80%;
    margin: auto;
}

#navIconText {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 21px;
    color: #6f6b6d;
    display: none;
    padding-left: 1rem;
}

@media only screen and (max-width: 830px) {
    .navLinkText {
        font-size: 17px;
        padding-left: 1rem;
    }

    #navbar {
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    #navIcon {
        position: absolute;
        top: 1.8rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}