#homeBodyContainer {
    overflow-x: hidden !important;
}

.homeHeroContainer {
    margin: auto;
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
}

#homeHeroImage {
    width: 20rem;
    height: auto;
}

#melImage {
    width: 20rem;
    padding: 1rem;
}

#homeHeroChild {
    margin: auto;
    text-align: center;
    width: 70%;
}

#homeHeroHeadingText {
    background: rgb(51, 51, 51);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(90deg, rgba(51, 51, 51, 1) 0%, rgba(102, 197, 48, 1) 100%);
}

#homeHeroTagline {
    margin-top: 1rem;
}

#homeHeroChildButtonContainer {
    margin-top: 1.5rem;
}

#homeHeroButton {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 21px;
    color: white;
    line-height: 1.1;
    padding: 0.8rem;
    background-color: #3164f4;
}

.homeClearCard {
    width: 80%;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 4rem;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
}

.homeClearHeading {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #333333;
    display: block;
    line-height: 1.1;
}

.homeClearText {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 21px;
    color: #86868b;
    display: block;
    line-height: 1.1;
    margin-top: 0.5rem;
}

.homeClearTextContainerRight {
    margin: auto;
    text-align: left;
    flex: 1 0 50%;
    padding-left: 1rem;
}

.homeClearTextContainerLeft {
    margin: auto;
    text-align: left;
    flex: 1 0 50%;
    padding-right: 1rem;
}

.homeClearImageContainerLeft {
    width: 15rem;
    height: auto;
    margin: auto;
    flex: 1 0 50%;
    text-align: left;
    padding-right: 1rem;
}

.homeClearImageContainerRight {
    width: 15rem;
    height: auto;
    margin: auto;
    flex: 1 0 50%;
    text-align: right;
    padding-left: 1rem;
}

.homeClearImage {
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px
}

.homeBodyButtonContainer {
    margin-top: 1rem;
}

#bodyClearButton {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 1.1;
    padding: 0.8rem;
    background-color: transparent;
    border: 1px solid #333333;
}

#bodyClearButton:hover {
    background-color: rgb(255, 255, 255);
}

#homeTestimonialsContainer {
    width: 80%;
    margin: auto;
    margin-top: 3rem;
}

#homeTestimonialCardContainer {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.homeTestimonialCard {
    width: 30%;
    padding: 2rem;
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: white;
    margin: auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 1rem;
    margin: 1rem;
    display: flex;
}

.homeTestimonialText {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: #333333;
    display: block;
    line-height: 1.1;
    margin: auto;
    height: 3rem;
}

.testimonalStarContainer {
    display: block;
    margin-top: 2rem;
}

.testimonialStarSpan {
    display: flex;
    align-items: center;
}

.testimonialStar {
    fill: #3164f4;
    display: inline-block;
    width: 1.2rem;
    height: auto;
}

.homeTestimonialCardTextContainer {
    margin: auto;
    margin-top: 1rem;
}

@media only screen and (max-width: 1030px) { 

    #homeTestimonialCardContainer {
        flex-direction: column;
    }

    .homeTestimonialCard {
        width: 100%;
        padding: 2rem;
        height: auto;
        margin: auto;
        margin-bottom: 1rem;
        width: 100%;
    }

    .homeTestimonialText {
        height: auto;
    }

    .testimonialStar {
        width: 1.5rem;
        height: auto;
    }
}

@media only screen and (max-width: 830px) {

    #homeHeroChild {
        width: 100%;
    }

    .homeClearTextContainerLeft,
    .homeClearTextContainerRight {
        flex: 1 0 100%;
        text-align: left;
        padding: 1rem;
    }

    .homeClearImageContainerLeft,
    .homeClearImageContainerRight {
        flex: 1 0 100%;
        text-align: center;
        width: 100%;
        padding: 0;
    }

    #homeBodySecondCard {
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width: 600px) {

    .homeClearCard {
        width: 90%;
        padding: 0;
    }

    .homeClearHeading {
        font-size: 21px;
    }

    .homeClearText {
        font-size: 17px;
    }

    #homeHeroButton,
    #bodyClearButton {
        font-size: 17px;
        padding: 0.7rem;
    }

    #melImage {
        width: 80%;
        max-width: 15rem;
        padding: 0rem;
    }
}