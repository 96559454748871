.contactHeadingContainer {
    margin: auto;
    margin-top: 6rem;
    margin-bottom: 5rem;
    width: 80%;
}

.contactContainer {
    margin: auto;
    width: 80%;
}

.contactFormContainer {
    height: 38.2rem;
}

.contactSection {
    max-width: 40rem;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}

.contactInputLabel {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: #333333;
    display: block;
    line-height: 1.1;
    float: left;
    margin-bottom: 0.5rem;
}

.contactInputError {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: rgba(255, 0, 0, 0.733);
    display: block;
    line-height: 1.1;
    float: left;
    margin-top: 0.5rem;
}

#contactSubmit {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: white;
    line-height: 1.1;
    padding: 0.8rem;
    width: 100%;
    max-width: 40rem;
    background-color: #3164f4;
    display: block;
}

#messageInput {
    height: 8rem;
}

.contactSuccesfulSubmissionContainer {
    margin: auto;
    width: 80%;
}

@media (max-width: 1080px) {    
    .contactHeadingContainer {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}