.servicesFeaturesContainer {
    width: 80%;
    margin: auto;
    overflow: hidden;
}

.servicesHeadingContainer {
    margin-top: 6rem;
    margin-bottom: 5rem;
}

.servicesBodyCard {
    width: 65%;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
}

.servicesBodyCardHeadingContainerLeft {
    width: 15rem;
    height: auto;
    margin: auto;
    flex: 1 0 50%;
    text-align: left;
}

.servicesBodyCardHeadingContainerRight {
    width: 15rem;
    height: auto;
    margin: auto;
    flex: 1 0 50%;
    text-align: right;
}

.servicesBodyCardHeadingWrapperRight {
    width: 80%;
    float: right;
}

.servicesBodyCardHeadingWrapperLeft {
    width: 60%;
}

.serviceBodyCardItemsContainerLeft {
    margin: auto;
    text-align: left;
    flex: 1 0 50%;
}

.servicesFeatureListIcon {
    fill: #3164f4;
    margin-right: 8px;
    height: 1.1rem;
    width: 1.1rem;
}

.servicesFeatureListItem {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: #333333;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    margin-top: 1.1rem;
    margin-left: 0rem;
    text-align: left;
}

.servicesButtonContainer {
    margin-top: 1rem;
}

#servicesClearButton {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 1.1;
    padding: 0.5rem 0.8rem 0.5rem 0.8rem;
    background-color: transparent;
    border: 1px solid #333333;
}

#servicesClearButton:hover {
    background-color: rgb(255, 255, 255);
}

.servicesWideImageContainer {
    width: 100%;
    height: 15rem;
    overflow: hidden;
}

.servicesWideImageWrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

#servicesWideImageWrapper1 {
    animation: zoomIn 2s ease-out;
}

#servicesWideImageWrapper2 {
    
}

#servicesWideImageWrapper3 {
}

#servicesLobbyImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center; /* This will center the middle of the image */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes zoomIn {
    from {
        transform: scale(2); /* Initial scale, fully zoomed out */
    }
    to {
        transform: scale(1); /* Final scale, original size */
    }
}

@media (max-width: 1080px) {
    .servicesBodyCard {
        width: 90%;
        padding: 0;
    }

    #servicesBodySecondCard {
        flex-direction: column-reverse;
    }

    .servicesBodyCardHeadingContainerLeft, .serviceBodyCardItemsContainerRight {
        width: 15rem;
        height: auto;
        margin: auto;
        flex: 1 0 100%;
        text-align: left;
        padding-left: 0;
    }

    .servicesBodyCardHeadingContainerRight, .serviceBodyCardItemsContainerLeft {
        width: 100%;
        height: auto;
        margin: auto;
        flex: 0 0 100%;
        text-align: left;
        padding-left: 0;
    }

    .servicesBodyCardHeadingWrapperRight, .servicesBodyCardHeadingWrapperLeft {
        width: 100%;
    }
    
    .servicesHeadingContainer {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    #servicesClearButton {
        font-size: 17px;
    }

    .servicesWideImageContainer {
        width: 100%;
        height: 9rem;
        overflow: hidden;
    }
}