.App {
  text-align: center;
}

body {
  /* background-color: #ededed; */
  background-color: #f5f5f7;
  overflow-x: hidden;
}

.heading {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 70px;
  color: #333333;
  display: block;
  line-height: 1.1;
}

.bodyHeading {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #333333;
  display: block;
  line-height: 1.1;
}

.bodyText {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 21px;
  color: #86868b;
  display: block;
  line-height: 1.1;
  margin-top: 0.5rem;
}

.pageHeaderContainer {
  margin: auto;
  width: 80%;
}

.bodyCard {
  background-color: white;
  width: 80%;
  margin: auto;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
}

.bodyCardTextContainer {
  margin: auto;
  text-align: left;
  flex: 1 0 50%;
  padding-left: 3rem;
}

.bodyCardImageContainer {
  width: auto;
  margin: auto;
  flex: 1 0 50%;
}

.cardImage {
  width: 15rem;
  height: auto;
}

@media only screen and (max-width: 830px) {
  .bodyCardTextContainer {
    flex: 1 0 100%;
    padding: 0;
  }

  .bodyCardImageContainer {
    flex: 1 0 100%;
    margin-top: 2rem;
  }

}

@media only screen and (max-width: 600px) {

  .heading {
    font-size: 40px;
  }

  .bodyHeading {
    font-size: 21px;
  }

  .bodyText {
    font-size: 17px;
  }

  .bodyCard {
    width: 90%;
  }

}