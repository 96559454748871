.footerContainer {
    width: 80%;
    border-top: 1px solid #bdbdbd;
    margin: auto;
}

.footerColumnContainer {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.footerColumn {
    flex-basis: 30%;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 20px;
}

.footerLink {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: #86868b;
    display: block;
    line-height: 1.1;
    margin-bottom: 1rem;
    text-decoration: none;
}

.footerLink:hover {
    color: #3164f4
}

#footerLogoColumn {
    text-align: center;
}

.footerIcon {
    height: 5rem;
    width: auto;
}

@media screen and (max-width: 1030px) {
    .footerColumnContainer {
        flex-wrap: wrap;
    }

    .footerColumn {
        flex-basis: 100%;
    }

    .footerColumn:nth-child(2),
    .footerColumn:nth-child(3) {
        flex-basis: 50%;
    }
}

@media screen and (max-width: 343px) {
    .footerColumn:nth-child(2),
    .footerColumn:nth-child(3) {
        flex-basis: 100%;
        padding: 0rem;
    }
}
